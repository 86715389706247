import React, { useState, useEffect } from 'react';

import { useFormContext, useWatch } from 'react-hook-form';

import { Box, InfoBox, Form, Icons, RadioPicker } from '@app/components';
import { date, date as dateLib } from '@app/lib';
import { TIME_TYPE } from '@app/constants';
import { useLocale } from '@app/hooks';

import CustomInput from './CustomInput';

type StartDatePropTypes = {
  startTime: string;
  isEdit?: boolean;
  isGooglePlatform?: boolean;
};

const StartDate: React.FC<StartDatePropTypes> = ({ startTime, isEdit = false, isGooglePlatform }) => {
  const SCOPE_OPTIONS = {
    scope: 'components.Campaign.DateForm.StartDate',
  };
  const { t } = useLocale();
  const [timeType, setTimeType] = useState<string>(TIME_TYPE.NOW_DATE);
  const {
    formState: { errors },
    setValue,
    control,
    watch,
  } = useFormContext();
  const OPTIONS = [
    { label: t('labels.useNow', SCOPE_OPTIONS), value: TIME_TYPE.NOW_DATE, date: true, disabled: false },
    { label: t('labels.useStartTime', SCOPE_OPTIONS), value: TIME_TYPE.DIFFERENT_START_DATE, date: true, disabled: false },
  ];
  const defaultDate = getDefaultDate();
  const startTimeWatch = useWatch({
    control,
    name: 'startTime',
    defaultValue: defaultDate,
  });
  const stopTimeWatch = watch('stopTime');
  const maxDate = getMaxDate();
  const minMaxTime = getMinMaxTime();

  useEffect(() => {
    if (timeType === TIME_TYPE.NOW_DATE) {
      setValue('startTime', defaultDate);
    }
  }, [timeType]);

  function getMaxDate() {
    if (!dateLib.isValid(stopTimeWatch)) {
      return undefined;
    }

    return dateLib.JSDateToISO(dateLib.minus(dateLib.ISOtoJSDate(stopTimeWatch), { hours: 24 }));
  }
  function getDefaultDate(): string {
    const current = dateLib.ISOtoJSDate(dateLib.now());
    const campaignStartTime = dateLib.ISOtoJSDate(startTime);

    if (isEdit) {
      return date.JSDateToISO(campaignStartTime);
    }

    if (campaignStartTime > current) {
      return date.JSDateToISO(campaignStartTime);
    }

    return date.JSDateToISO(current);
  }

  function handleRadioSelect(value: string) {
    setTimeType(value);
  }

  function getMinMaxTime() {
    const diffDate = dateLib.differenceDate(
      dateLib.ISOtoJSDate(dateLib.JSDateToISO(dateLib.minus(dateLib.ISOtoJSDate(defaultDate), { hours: 24 }))),
      dateLib.ISOtoJSDate(startTimeWatch)
    );
    const stopDiffDate = maxDate && dateLib.differenceDate(dateLib.ISOtoJSDate(maxDate), dateLib.ISOtoJSDate(startTimeWatch));
    if (diffDate !== 1 && stopDiffDate) {
      return {
        minTime: dateLib.ISOtoJSDate(
          dateLib.set(date.now(), {
            hour: 0,
            minute: 0,
          })
        ),
        maxTime: dateLib.ISOtoJSDate(
          dateLib.set(date.now(), {
            hour: 23,
            minute: 59,
          })
        ),
      };
    }

    if (!stopDiffDate && maxDate) {
      return {
        minTime: dateLib.ISOtoJSDate(
          dateLib.set(maxDate, {
            hour: 0,
            minute: 0,
          })
        ),
        maxTime: dateLib.ISOtoJSDate(dateLib.format(maxDate, 'HH:mm')),
      };
    }

    if (!stopDiffDate && diffDate !== 1) {
      return {
        minTime: dateLib.ISOtoJSDate(
          dateLib.set(date.now(), {
            hour: 0,
            minute: 0,
          })
        ),
        maxTime: dateLib.ISOtoJSDate(
          dateLib.set(date.now(), {
            hour: 23,
            minute: 59,
          })
        ),
      };
    }

    return {
      minTime: dateLib.ISOtoJSDate(defaultDate),
      maxTime: dateLib.ISOtoJSDate(
        dateLib.set(date.now(), {
          hour: 23,
          minute: 59,
        })
      ),
    };
  }

  return (
    <Box className="bg-white p-5 !mb-0">
      <div className="flex items-center justify-between mb-1.5">
        <label className="block text-3.5 font-semibold text-gray-900">
          {t('labels.startDate', SCOPE_OPTIONS)}
          <span className="text-red-400">*</span>
        </label>
      </div>
      <div className="flex items-center space-x-4">
        <InfoBox className="w-3/4">
          <div className="flex items-center space-x-2 text-center rounded-1.5 text-black-800 text-3.5 font-semibold transition-all">
            <Icons.Date className="w-5 h-5" />
            <span>{date.format(startTimeWatch, 'dd MMM yyyy')}</span>
          </div>
        </InfoBox>
        {!isGooglePlatform && (
          <InfoBox className="w-1/4">
            <div className="flex items-center space-x-2 text-center rounded-1.5 text-black-800 text-3.5 font-semibold transition-all">
              <Icons.ClockCircle className="w-5 h-5" />
              <span>{date.format(startTimeWatch, 'HH:mm')} </span>
            </div>
          </InfoBox>
        )}
      </div>
      {isEdit === false && (
        <>
          <div className="border-b border-gray-400 w-full my-6"></div>
          <div className="flex flex-col">
            <RadioPicker className="flex items-center space-x-20" options={OPTIONS} value={timeType} onChange={handleRadioSelect} />
            {timeType === TIME_TYPE.DIFFERENT_START_DATE && (
              <Form.Date
                id="startTime"
                name="startTime"
                dateFormat={isGooglePlatform ? 'd MMMM yyyy' : 'd MMMM yyyy HH:mm'}
                timeIntervals={1}
                showTimeSelect={!isGooglePlatform ?? true}
                minDate={dateLib.ISOtoJSDate(defaultDate)}
                maxDate={dateLib.isValid(maxDate) ? dateLib.ISOtoJSDate(maxDate as string) : undefined}
                minTime={minMaxTime.minTime}
                maxTime={minMaxTime.maxTime}
                control={control}
                error={errors.startTime}
                defaultValue={startTimeWatch}
                customInput={<CustomInput />}
              />
            )}
          </div>
        </>
      )}
    </Box>
  );
};

export default StartDate;
