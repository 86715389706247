import React, { useState, useContext, createContext } from 'react';

import Cookies from 'js-cookie';

import type { BranchUserTokenSuccessResponse, Branch as BranchType, AccountResponse as AccountResponseType } from '@app/api';
import { COOKIE_KEYS } from '@app/constants';
import { api } from '@app/hooks';

type ReturnTypes = {
  facebookAccount: AccountResponseType | null;
  branch: BranchType;
  branchId: string;
  token: BranchUserTokenSuccessResponse | null;
  storeToken: (token: BranchUserTokenSuccessResponse) => void;
  storeBranch: (branch: BranchType, storeBranch?: boolean) => void;
  storeFacebookAccount: (branch: AccountResponseType) => void;
  refreshToken: () => void;
  signOut: () => void;
  isRefreshTokenLoading: boolean;
};

const REFRESH_TOKEN_GRANT_TYPE = 'refresh_token';
const cookieToken = Cookies.get(COOKIE_KEYS.TOKEN);
const parsedToken = cookieToken ? JSON.parse(cookieToken) : null;

const cookieBranchId = Cookies.get(COOKIE_KEYS.BRANCH_ID);
const parsedBranchId = cookieBranchId ? JSON.parse(cookieBranchId) : null;

const cookieBranch = Cookies.get(COOKIE_KEYS.BRANCH);
const parsedBranch = cookieBranch ? JSON.parse(cookieBranch) : null;

const cookieFacebookAccount = Cookies.get(COOKIE_KEYS.FACEBOOK_ACCOUNT);
const parsedFacebookAccount = cookieFacebookAccount ? JSON.parse(cookieFacebookAccount) : null;

const AuthContext = createContext({} as ReturnTypes);

export const AuthProvider: React.FC = ({ children }) => {
  const [token, setToken] = useState<BranchUserTokenSuccessResponse | null>(parsedToken);
  const [branchId, setBranchId] = useState<string>(parsedBranchId);
  const [facebookAccount, setFacebookAccount] = useState<AccountResponseType | null>(parsedFacebookAccount);
  const [branch, setBranch] = useState<BranchType>(parsedBranch);
  const { mutate, isLoading: isRefreshTokenLoading } = api.useBranchUserLogin({
    onSuccess: (data: BranchUserTokenSuccessResponse) => {
      storeToken(data);

      window.location.reload();
    },
    onError: () => signOut(),
  });

  function signOut() {
    Cookies.remove(COOKIE_KEYS.TOKEN);
    Cookies.remove(COOKIE_KEYS.BRANCH_ID);
    Cookies.remove(COOKIE_KEYS.BRANCH);
    Cookies.remove(COOKIE_KEYS.FACEBOOK_ACCOUNT);
    localStorage.removeItem('messagingApplication');

    if (token !== null) {
      window.location.reload();
    }
  }

  function storeToken(token: BranchUserTokenSuccessResponse) {
    setToken(token);
    Cookies.set(COOKIE_KEYS.TOKEN, JSON.stringify(token));
  }

  function refreshToken() {
    if (token === null) {
      signOut();
      return;
    }

    mutate({
      grant_type: REFRESH_TOKEN_GRANT_TYPE,
      refresh_token: token?.refresh_token,
      client_id: process.env.NEXT_PUBLIC_API_CLIENT_ID,
      client_secret: process.env.NEXT_PUBLIC_API_CLIENT_SECRET,
    });
  }

  function storeBranch(branch: BranchType, storeState = true) {
    const branchSummary: Partial<BranchType> = {
      id: branch.id,
      name: branch.name,
      address: branch.address,
      address_direction: branch.address_direction,
      website: branch.website,
      phone_number: branch.phone_number,
      default_facebook_city: branch.default_facebook_city,
      default_google_city: branch.default_google_city,
      default_custom_location: branch.default_custom_location,
      is_onboarded: branch.is_onboarded,
      force_open_facebook_login_modal: branch.force_open_facebook_login_modal,
      owner: branch.owner,
      facebook_integration: branch.facebook_integration,
      google_integration: branch.google_integration,
      company: {
        name: branch.company?.name,
        legal_name: branch.company?.legal_name,
        feature_flags: branch.company?.feature_flags,
      },
      allow_gm_update_campaigns: branch.allow_gm_update_campaigns,
      legal_information: branch.legal_information,
    };

    Cookies.set(COOKIE_KEYS.BRANCH, JSON.stringify(branchSummary));
    Cookies.set(COOKIE_KEYS.BRANCH_ID, JSON.stringify(branch.id));

    if (storeState) {
      setBranch(branch);
      setBranchId(branch.id);
    }
  }

  function storeFacebookAccount(account: AccountResponseType) {
    setFacebookAccount(account);
    Cookies.set(COOKIE_KEYS.FACEBOOK_ACCOUNT, JSON.stringify(account));
  }

  if (token === undefined) {
    return null;
  }

  return (
    <AuthContext.Provider
      value={{
        token,
        storeToken,
        facebookAccount,
        storeFacebookAccount,
        branch,
        branchId,
        storeBranch,
        refreshToken,
        isRefreshTokenLoading,
        signOut,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default function useAuth(): ReturnTypes {
  return useContext(AuthContext);
}
