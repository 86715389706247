/* tslint:disable */
/* eslint-disable */
/**
 * Branchsight API
 * Branchsight API documentation
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: tech@branchsight.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { AuditableBranch } from './auditable-branch';
import { AuditableBranchCampaign } from './auditable-branch-campaign';
import { AuditableBranchUser } from './auditable-branch-user';
import { AuditableCampaign } from './auditable-campaign';
import { AuditableCompany } from './auditable-company';
import { AuditableFacebookIntegration } from './auditable-facebook-integration';
import { AuditableTemplate } from './auditable-template';
import { Branch } from './branch';
import { BranchCampaignUpdatedLog } from './branch-campaign-updated-log';
import { BranchPausedLog } from './branch-paused-log';
import { BranchUpdatedAddressLog } from './branch-updated-address-log';
import { BranchUpdatedLog } from './branch-updated-log';
import { BranchUser } from './branch-user';
import { BranchUserCreatedLog } from './branch-user-created-log';
import { CampaignUpdatedLog } from './campaign-updated-log';
import { Company } from './company';
import { FacebookIntegrationResponse } from './facebook-integration-response';
import { Template } from './template';
import { TemplateArchivedLog } from './template-archived-log';
import { TemplateDuplicatedLog } from './template-duplicated-log';
import { TemplateUpdatedLog } from './template-updated-log';

/**
 * 
 * @export
 * @interface GmAuditLog
 */
export interface GmAuditLog {
    /**
     * 
     * @type {string}
     * @memberof GmAuditLog
     */
    id: string;
    /**
     * 
     * @type {AuditableBranchUser | AuditableBranch | AuditableBranchCampaign | AuditableCampaign | AuditableTemplate | AuditableFacebookIntegration | AuditableCompany}
     * @memberof GmAuditLog
     */
    auditable: AuditableBranchUser | AuditableBranch | AuditableBranchCampaign | AuditableCampaign | AuditableTemplate | AuditableFacebookIntegration | AuditableCompany;
    /**
     * 
     * @type {Company}
     * @memberof GmAuditLog
     */
    company: Company;
    /**
     * 
     * @type {Branch}
     * @memberof GmAuditLog
     */
    branch?: Branch;
    /**
     * 
     * @type {BranchUser}
     * @memberof GmAuditLog
     */
    branch_user?: BranchUser;
    /**
     * 
     * @type {string}
     * @memberof GmAuditLog
     */
    event: string;
    /**
     * 
     * @type {string}
     * @memberof GmAuditLog
     */
    log: GmAuditLogLogEnum;
    /**
     * 
     * @type {BranchUserCreatedLog | BranchUpdatedAddressLog | BranchPausedLog | CampaignUpdatedLog | BranchUpdatedLog | TemplateUpdatedLog | TemplateDuplicatedLog | TemplateArchivedLog | BranchCampaignUpdatedLog | FacebookIntegrationResponse | Template}
     * @memberof GmAuditLog
     */
    payload: BranchUserCreatedLog | BranchUpdatedAddressLog | BranchPausedLog | CampaignUpdatedLog | BranchUpdatedLog | TemplateUpdatedLog | TemplateDuplicatedLog | TemplateArchivedLog | BranchCampaignUpdatedLog | FacebookIntegrationResponse | Template;
    /**
     * 
     * @type {string}
     * @memberof GmAuditLog
     */
    created_at: string;
    /**
     * 
     * @type {string}
     * @memberof GmAuditLog
     */
    updated_at: string;
}

/**
    * @export
    * @enum {string}
    */
export enum GmAuditLogLogEnum {
    BranchCreated = 'branch_created',
    BranchUpdated = 'branch_updated',
    BranchDestroyed = 'branch_destroyed',
    BranchAddressUpdated = 'branch_address_updated',
    BranchFacebookIntegrationCreated = 'branch_facebook_integration_created',
    BranchFacebookIntegrationUpdated = 'branch_facebook_integration_updated',
    BranchFacebookIntegrationDestroyed = 'branch_facebook_integration_destroyed',
    BranchGoogleIntegrationDestroyed = 'branch_google_integration_destroyed',
    CompanyFacebookIntegrationCreated = 'company_facebook_integration_created',
    CompanyFacebookIntegrationUpdated = 'company_facebook_integration_updated',
    CompanyFacebookIntegrationDestroyed = 'company_facebook_integration_destroyed',
    TemplateArchived = 'template_archived',
    TemplateCreated = 'template_created',
    TemplateUpdated = 'template_updated',
    TemplateDuplicated = 'template_duplicated',
    TemplateDestroyed = 'template_destroyed',
    UserRoleCreated = 'user_role_created',
    UserRoleUpdated = 'user_role_updated',
    BranchCampaignCreated = 'branch_campaign_created',
    BranchCampaignUpdated = 'branch_campaign_updated',
    BranchCampaignDestroyed = 'branch_campaign_destroyed',
    CompanyCampaignCreated = 'company_campaign_created',
    CompanyCampaignUpdated = 'company_campaign_updated',
    GroupCreated = 'group_created',
    GroupUpdated = 'group_updated',
    GroupArchived = 'group_archived',
    GroupDestroyed = 'group_destroyed',
    AudienceCreated = 'audience_created',
    AudienceDestroyed = 'audience_destroyed',
    BranchCampaignUpdatedFromFacebook = 'branch_campaign_updated_from_facebook',
    CanvaItemCreated = 'canva_item_created',
    CanvaItemUpdated = 'canva_item_updated',
    CanvaImageCreated = 'canva_image_created'
}



