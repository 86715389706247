import { Icons } from '@app/components';

type CustomInputType = {
  value?: string;
  onClick?: () => void;
  hour?: boolean;
  scheduleHour?: boolean;
};

const CustomInput: React.FC<CustomInputType> = ({ value, onClick, hour, scheduleHour }) => (
  <div
    className="flex border border-black-800 rounded-2 items-center text-center space-x-4 px-2 py-1.5 justify-between w-fit"
    onClick={onClick}
    aria-hidden="true"
  >
    <span className="flex items-center space-x-1.5">
      {hour || scheduleHour ? <Icons.ClockCircle className="w-5 h-5" /> : <Icons.Date className="w-5 h-5" />}
      <span>{value}</span>
    </span>
    {!hour && <Icons.ChevronDown className="w-5 h-5" />}
  </div>
);

export default CustomInput;
