import React from 'react';

import { useFormContext } from 'react-hook-form';

import { Box } from '@app/components';
import { api, useAuth, useLocale } from '@app/hooks';
import { format, campaign as campaignLib } from '@app/lib';
import { Campaign as CampaignType, BranchCampaign as BranchCampaignType } from '@app/api';
import GEO_LOCATION_TYPE from 'constants/geoLocationType';

type ReachEstimatePropTypes = {
  campaign: Partial<CampaignType> & Partial<BranchCampaignType>;
  radius?: number;
};

const ReachEstimate: React.FC<ReachEstimatePropTypes> = ({ campaign, radius }) => {
  const SCOPE_OPTIONS = {
    scope: 'components.Campaign.ReachEstimate',
  };
  const { t, locale } = useLocale();
  const { watch, setValue } = useFormContext();
  const { branch } = useAuth();
  const targetingWatch = watch();
  const { data } = api.useCalculateBranchFacebookReachEstimate({
    params: {
      targeting: handleReactParams(),
    },
    onSuccess: (data: any) => {
      setValue('maxEstimate', data?.users_upper_bound);
    },
  });

  const budgetWatch = watch('budget');
  const campaignScope = budgetWatch && campaignLib.calculateCampaignScope(budgetWatch);
  const campaignScopeFormat = format.number(campaignScope, {
    isCompact: true,
    locale: locale,
  });

  function handleReactParams() {
    const radiusDefaultValue =
      radius ?? campaign?.targeting?.geo_locations?.custom_locations?.radius ?? campaign?.targeting?.default_location_range;
    const citiesDefaultValue = campaign?.targeting?.geo_locations?.cities ?? [
      {
        key: branch.default_facebook_city?.key,
        radius: branch.default_facebook_city?.radius,
        distance_unit: branch.default_facebook_city?.distance_unit,
      },
    ];

    const targeting = {
      genders: targetingWatch.gender ?? campaign?.targeting?.gender,
      age_min: targetingWatch.ageMin ?? campaign?.targeting?.age_min,
      age_max: targetingWatch.ageMax ?? campaign?.targeting?.age_max,
      interests: targetingWatch.interests
        ? targetingWatch.interests
            ?.filter((item: { type: string }) => item.type === 'interests')
            ?.map((item: { value: string; label: string }) => ({
              id: String(item.value),
              name: item.label,
            }))
        : campaign?.targeting?.interests ?? [],
      geo_locations: {} as any,
      publisher_platforms: campaign.template?.targeting.publisher_platforms,
      facebook_positions: campaign.template?.targeting.facebook_positions,
      instagram_positions: campaign.template?.targeting.instagram_positions,
    };

    if (campaign?.geo_location_type === GEO_LOCATION_TYPE.CITY || targetingWatch?.cities) {
      targeting.geo_locations.cities = (targetingWatch.cities ?? citiesDefaultValue)?.map(
        (city: { value: string; radius: number; distance_unit: string }) => ({
          key: city.value ?? branch.default_facebook_city?.key,
          radius: branch.default_facebook_city?.radius,
          distance_unit: branch.default_facebook_city?.distance_unit,
        })
      );
    }

    if (campaign?.geo_location_type === GEO_LOCATION_TYPE.RANGE || targetingWatch?.radius) {
      targeting.geo_locations.custom_locations = [
        {
          radius: targetingWatch.radius ?? radiusDefaultValue ?? branch?.default_custom_location?.radius,
          latitude: branch.location.latitude,
          longitude: branch.location.longitude,
          distance_unit: branch?.default_custom_location?.distance_unit,
        },
      ];
    }

    if (!targetingWatch?.cities && !targetingWatch?.radius && campaign?.geo_location_type === GEO_LOCATION_TYPE.FREE) {
      targeting.geo_locations = {
        countries: ['TR'],
      };
    }

    return targeting;
  }

  function estimatedSize(estimateData: typeof data): string | null {
    if (!estimateData?.estimate_ready) {
      return null;
    }

    if (estimateData?.estimate_ready === false) {
      return null;
    }

    const lowerBound = format.number(estimateData?.users_lower_bound, {
      isCompact: true,
      locale: locale,
    });
    const upperBound = format.number(estimateData?.users_upper_bound, {
      isCompact: true,
      locale: locale,
    });

    return `${lowerBound} - ${upperBound}`;
  }

  return (
    <>
      <Box className="w-full h-26 shadow-sm bg-white">
        <div className="flex flex-col items-center w-full">
          <span className="font-semibold text-4 text-gray-800">{t('labels.titles.dailyEstimated', SCOPE_OPTIONS)}</span>
          <span className="font-semibold text-5.5 leading-6 text-blue-300">{estimatedSize(data)}</span>
        </div>
      </Box>
      <Box className="w-full h-26 shadow-sm bg-white">
        <div className="flex flex-col items-center w-full">
          <span className="font-semibold text-4 text-gray-800">{t('labels.titles.dailyScope', SCOPE_OPTIONS)}</span>
          <span className="font-semibold text-5.5 leading-6 text-blue-300">{campaignScopeFormat}</span>
        </div>
      </Box>
    </>
  );
};

export default ReachEstimate;
