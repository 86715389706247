import React, { useEffect, useState } from 'react';

import { useFormContext } from 'react-hook-form';

import { CAMPAIGN_TYPES, CALL_TO_ACTION, DESTINATION_TYPES, TARGETING_TYPES, ICON_TYPE, CURRENCY as CURRENCY_MAP } from '@app/constants';
import { Box, Button, Form } from '@app/components';
import type { BranchCampaign as CampaignType, CompanyCampaign, CompanyCampaignImageVariables } from '@app/api';
import { api, useAuth, useLocale } from '@app/hooks';
import { url as urlLib, phoneNumber, campaign as campaignLib, branch as branchLib } from '@app/lib';

import ObjectiveFormPicker from './ObjectiveFormPicker';

type ObjectiveFormPropTypes = {
  campaign: CampaignType | CompanyCampaign;
  isEdit?: boolean;
  hasLeadGen?: boolean;
  allowedObjectives?: string[] | undefined;
  createNewImages: any;
};

const ObjectiveForm: React.FC<ObjectiveFormPropTypes> = ({ campaign, isEdit, hasLeadGen, allowedObjectives, createNewImages }) => {
  const SCOPE_OPTIONS = {
    scope: 'components.Campaign.ObjectiveForm',
  };
  const { t } = useLocale();
  const {
    watch,
    setValue,
    register,
    unregister,
    control,
    clearErrors,
    formState: { errors },
  } = useFormContext();
  const { branch, branchId } = useAuth();
  const campaignType = watch('campaignType');
  const phoneNumberWatch = watch('phoneNumber');
  const updateVariablesWatch = watch('updateVariables');
  const [selectedSubOption, setSelectedSubOption] = useState(true);
  const { data: pages } = api.useGetCurrentFacebookPageResponse({
    params: {
      branchId: branchId,
    },
  });
  const currency = branchLib.currency(branch);

  const instagramPage = pages?.instagram_account;
  const hasWhatsappNumber = !!pages?.has_whatsapp_business_number;
  const hasInstagramPage = pages?.instagram_account ? true : false;
  const foundedCampaignType = campaignLib.getCampaignType(campaign);
  const allMessageValue = campaignType === CAMPAIGN_TYPES.WHATSAPP_MESSAGE ? CAMPAIGN_TYPES.WHATSAPP_MESSAGE : CAMPAIGN_TYPES.MESSENGER;

  useEffect(() => {
    if (phoneNumberWatch) {
      const phoneUri = phoneNumber.uri(phoneNumberWatch);

      handleSelect(CAMPAIGN_TYPES.CALL, phoneUri ?? '');
    }
  }, [phoneNumberWatch]);

  useEffect(() => {
    if (isEdit && foundedCampaignType) {
      handleSelect(foundedCampaignType);

      if (foundedCampaignType === CAMPAIGN_TYPES.CALL) {
        setValue('phoneNumber', (campaign as CampaignType)?.creative?.link_data.call_to_action?.value);
      }

      if ([CAMPAIGN_TYPES.FINAL_URL, CAMPAIGN_TYPES.INSTAGRAM_VISIT].includes(foundedCampaignType as any)) {
        setValue('url', (campaign as CampaignType)?.creative?.link_data.link);
      }

      if (foundedCampaignType === CAMPAIGN_TYPES.MESSENGER) {
        setValue('website', (campaign as CampaignType)?.creative?.link_data.link);
      }
      return;
    }
  }, [campaign]);

  useEffect(() => {
    if (campaignType !== CAMPAIGN_TYPES.FINAL_URL) {
      unregister('url');
    }

    if (campaignType !== CAMPAIGN_TYPES.CALL) {
      unregister('phoneNumber');
    }

    if (campaignType !== CAMPAIGN_TYPES.INSTAGRAM_VISIT) {
      unregister('instagramUsername');
    }

    if (campaignType) {
      clearErrors('campaignType');
    }
  }, [campaignType]);

  const TARGET_OPTIONS = () =>
    [
      {
        label: t('labels.allMessage', SCOPE_OPTIONS),
        value: allMessageValue,
        disabled: campaignLib.campaignTypeNotAwailable(allowedObjectives, [TARGETING_TYPES.WHATSAPP_MESSAGE, TARGETING_TYPES.MESSENGER]),
        Icon: ICON_TYPE.ALL_MESSAGE,
      },
      {
        label: t('labels.web', SCOPE_OPTIONS),
        value: CAMPAIGN_TYPES.FINAL_URL,
        disabled: campaignLib.campaignTypeNotAwailable(allowedObjectives, [TARGETING_TYPES.FINAL_URL]),
        Icon: ICON_TYPE.FINAL_URL,
      },
      {
        label: t('labels.call', SCOPE_OPTIONS),
        value: CAMPAIGN_TYPES.CALL,
        disabled: campaignLib.campaignTypeNotAwailable(allowedObjectives, [TARGETING_TYPES.CALL_NOW]),
        Icon: ICON_TYPE.CALL,
      },
      {
        label: t('labels.leadGen', SCOPE_OPTIONS),
        value: CAMPAIGN_TYPES.LEAD_GENERATION,
        disabled: campaignLib.campaignTypeNotAwailable(allowedObjectives, [TARGETING_TYPES.LEAD_GENERATION]),
        Icon: ICON_TYPE.LEAD_GENERATION,
      },
      {
        label: t('labels.profileVisit', SCOPE_OPTIONS),
        value: CAMPAIGN_TYPES.INSTAGRAM_VISIT,
        disabled: campaignLib.campaignTypeNotAwailable(allowedObjectives, [TARGETING_TYPES.INSTAGRAM_VISIT]),
        Icon: ICON_TYPE.PERSON,
      },
    ].map((option) => {
      if (isEdit) {
        if (option.value === foundedCampaignType) {
          return { ...option, disabled: false };
        }

        return { ...option, disabled: true };
      }

      if (option.value === CAMPAIGN_TYPES.INSTAGRAM_VISIT) {
        if (hasInstagramPage === false) {
          return { ...option, disabled: true };
        }
      }

      if (option.value === CAMPAIGN_TYPES.WHATSAPP_MESSAGE) {
        if (hasWhatsappNumber === false) {
          return { ...option, disabled: true };
        }
      }

      if (option.value === CAMPAIGN_TYPES.LEAD_GENERATION) {
        if (!hasLeadGen) {
          return { ...option, disabled: true };
        }
      }

      return option;
    });

  const OPTIONS = () =>
    [
      {
        label: t('campaignTypes.whatsappMessage'),
        value: CAMPAIGN_TYPES.WHATSAPP_MESSAGE,
        disabled: campaignLib.campaignTypeNotAwailable(allowedObjectives, [TARGETING_TYPES.WHATSAPP_MESSAGE]),
        Icon: ICON_TYPE.WHATSAPP_MESSAGE,
      },
      {
        label: t('campaignTypes.messenger'),
        value: CAMPAIGN_TYPES.MESSENGER,
        disabled: campaignLib.campaignTypeNotAwailable(allowedObjectives, [TARGETING_TYPES.MESSENGER]),
        Icon: ICON_TYPE.MESSENGER,
      },
    ].map((option) => {
      if (isEdit) {
        if (option.value === foundedCampaignType) {
          return { ...option, disabled: false };
        }

        return { ...option, disabled: true };
      }

      if (option.value === CAMPAIGN_TYPES.WHATSAPP_MESSAGE) {
        if (hasWhatsappNumber === false) {
          return { ...option, disabled: true };
        }
      }

      return option;
    });

  function getWebsite(fallback: string) {
    if (branch?.website || branch?.website === '') {
      return fallback;
    }

    return branch?.website;
  }

  function handleSelect(value: string, extra = '') {
    setValue('campaignType', value);

    if (value === CAMPAIGN_TYPES.WHATSAPP_MESSAGE) {
      setValue('destinationType', DESTINATION_TYPES.WHATSAPP);
      setValue('targetingType', TARGETING_TYPES.WHATSAPP_MESSAGE);
      setValue('linkData', {
        link: getWebsite('https://api.whatsapp.com/send'),
        call_to_action: {
          type: CALL_TO_ACTION.WHATSAPP_MESSAGE,
          value: {
            app_destination: DESTINATION_TYPES.WHATSAPP,
          },
        },
      });
    }

    if (value === CAMPAIGN_TYPES.MESSENGER) {
      setValue('destinationType', DESTINATION_TYPES.MESSENGER);
      setValue('targetingType', TARGETING_TYPES.MESSENGER);
      setValue('linkData', {
        link: extra.length === 0 ? branch.website : extra,
        call_to_action: {
          type: CALL_TO_ACTION.MESSAGE_PAGE,
          value: {
            app_destination: DESTINATION_TYPES.MESSENGER,
          },
        },
      });
    }

    if (value === CAMPAIGN_TYPES.CALL) {
      setValue('destinationType', DESTINATION_TYPES.PHONE_CALL);
      setValue('targetingType', TARGETING_TYPES.CALL_NOW);
      setValue('linkData', {
        link: getWebsite(`https://fb.me/`),
        call_to_action: {
          type: CALL_TO_ACTION.CALL_NOW,
          value: {
            link: extra,
          },
        },
      });
    }

    if (value === CAMPAIGN_TYPES.FINAL_URL) {
      setValue('destinationType', undefined);
      setValue('targetingType', TARGETING_TYPES.FINAL_URL);
      setValue('linkData', {
        link: extra,
        call_to_action: {
          type: CALL_TO_ACTION.LEARN_MORE,
        },
      });
    }

    if (value === CAMPAIGN_TYPES.LEAD_GENERATION) {
      setValue('destinationType', undefined);
      setValue('targetingType', TARGETING_TYPES.LEAD_GENERATION);
    }

    if (value === CAMPAIGN_TYPES.INSTAGRAM_VISIT) {
      setValue('destinationType', undefined);
      setValue('targetingType', TARGETING_TYPES.INSTAGRAM_VISIT);
      setValue('linkData', {
        link: `https://www.instagram.com/${instagramPage?.username}`,
        call_to_action: {
          type: CALL_TO_ACTION.LEARN_MORE,
        },
      });
    }
  }

  useEffect(() => {
    if (
      campaignType === CAMPAIGN_TYPES.ALL_MESSAGE ||
      campaignType === CAMPAIGN_TYPES.WHATSAPP_MESSAGE ||
      campaignType === CAMPAIGN_TYPES.MESSENGER
    ) {
      setSelectedSubOption(true);
    }

    if (
      campaignType !== CAMPAIGN_TYPES.ALL_MESSAGE &&
      campaignType !== CAMPAIGN_TYPES.WHATSAPP_MESSAGE &&
      campaignType !== CAMPAIGN_TYPES.MESSENGER
    ) {
      setSelectedSubOption(false);
    }
  }, [campaignType]);

  return (
    <>
      <div className="mb-4 mt-8">
        <span className="block text-3.5 font-semibold text-gray-900 mb-4 text-lg">{t('title.target', SCOPE_OPTIONS)}</span>
        <ObjectiveFormPicker
          name="campaignType"
          className="grid grid-cols-3 gap-3 mt-2 "
          options={TARGET_OPTIONS().filter((item) => item.disabled === false)}
          value={campaignType}
          onChange={handleSelect}
          hasMainOption
          control={control}
          rules={{
            required: { value: true, message: t('form.campaignType.errors.required', SCOPE_OPTIONS) },
          }}
          error={errors.campaignType}
        />
      </div>
      {selectedSubOption && (
        <div className="mb-4">
          <span className="block text-3.5 font-semibold text-black-800 text-lg mb-4"> {t('title.appMessage', SCOPE_OPTIONS)}</span>
          <ObjectiveFormPicker
            name="campaignType"
            className="grid grid-cols-3 gap-3 mt-2"
            options={OPTIONS()}
            value={campaignType}
            onChange={handleSelect}
          />
        </div>
      )}

      {campaignType === CAMPAIGN_TYPES.FINAL_URL && (
        <>
          <span className="text-lg font-semibold"> {t('form.url.label', SCOPE_OPTIONS)}</span>
          <Box className="bg-white shadow-sm px-5 py-4.5">
            <Form.Input
              type="text"
              id="url"
              placeholder={t('form.url.placeholder', SCOPE_OPTIONS)}
              {...register('url', {
                onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
                  handleSelect(CAMPAIGN_TYPES.FINAL_URL, e.target.value);
                },
                required: { value: true, message: t('form.url.errors.required', SCOPE_OPTIONS) },
                validate: (url) => {
                  if (urlLib.isValid(url)) {
                    return true;
                  }

                  return t('form.url.errors.validate', SCOPE_OPTIONS);
                },
              })}
              disabled={isEdit}
              error={errors.url}
              Icon="Website"
            />
          </Box>
        </>
      )}
      {campaignType === CAMPAIGN_TYPES.MESSENGER && (
        <>
          <span className="text-lg font-semibold">{t('form.website.label', SCOPE_OPTIONS)}</span>
          <Box className="bg-white shadow-sm px-5 py-4.5">
            <Form.Input
              type="text"
              id="website"
              placeholder={t('form.website.placeholder', SCOPE_OPTIONS)}
              {...register('website', {
                onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
                  handleSelect(CAMPAIGN_TYPES.MESSENGER, e.target.value);
                },
                required: { value: true, message: t('form.website.errors.required', SCOPE_OPTIONS) },
                validate: (url) => {
                  if (urlLib.isValid(url)) {
                    return true;
                  }

                  return t('form.website.errors.validate', SCOPE_OPTIONS);
                },
              })}
              disabled={isEdit}
              defaultValue={branch.website}
              error={errors.website}
              Icon="Messenger"
            />
          </Box>
        </>
      )}

      {campaignType === CAMPAIGN_TYPES.CALL && (
        <>
          <span className="text-lg font-semibold mt-6"> {t('form.phoneNumber.label', SCOPE_OPTIONS)}</span>
          <Box className="bg-white shadow-sm px-5 py-4.5">
            <Form.PhoneInput
              className="mb-4.5"
              control={control}
              name="phoneNumber"
              id="phoneNumber"
              rules={{
                required: { value: true, message: t('form.phoneNumber.errors.required', SCOPE_OPTIONS) },
                validate: (phoneNumberString: string) => {
                  if (phoneNumber.isValid(phoneNumberString)) {
                    return true;
                  }

                  return t('form.phoneNumber.errors.validate', SCOPE_OPTIONS);
                },
              }}
              disabled={true}
              error={errors.phoneNumber}
              defaultValue={branch.phone_number}
            />
          </Box>
        </>
      )}

      {!isEdit && ((campaign as CompanyCampaign)?.image_variables as Array<CompanyCampaignImageVariables>)?.length > 0 && (
        <div className="flex items-center justify-between border-t mt-4 mb-4 border-gray-500 ">
          <Form.Checkbox
            id="updateVariables"
            className="mt-4"
            {...register('updateVariables')}
            label={t('labels.updateVariables', SCOPE_OPTIONS)}
          />
        </div>
      )}
      {updateVariablesWatch && (
        <div className="flex flex-col gap-4">
          {(campaign as CompanyCampaign)?.image_variables?.map(
            ({ key, value, localize_label }: { key: string; value: string; localize_label: string }) => {
              return (
                <div key={key}>
                  <label htmlFor={key} className="font-bold">
                    {localize_label}
                  </label>
                  <div className="relative">
                    {key === 'VAR_PRICE' && (
                      <>
                        <b>
                          <span className="absolute left-4 top-[56%] translate-y-[-50%]">{CURRENCY_MAP[currency]}</span>
                        </b>
                        <div className="absolute left-9 top-[56%] translate-y-[-50%] w-[1px] h-[50%] bg-black-800"></div>
                      </>
                    )}
                    <Form.Input
                      inputClassname={key === 'VAR_PRICE' ? 'pl-8' : undefined}
                      type={key === 'VAR_PRICE' ? 'number' : 'text'}
                      defaultValue={value}
                      id={key}
                      {...register(`variables.${key}`)}
                    ></Form.Input>
                  </div>
                </div>
              );
            }
          )}

          <Button
            block={false}
            className="ml-auto w-[30%] mt-2"
            theme="blue"
            type="button"
            onClick={createNewImages}
            label="Kaydet"
          ></Button>
        </div>
      )}
    </>
  );
};

export default ObjectiveForm;
