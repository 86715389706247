import React from 'react';

import { useFormContext } from 'react-hook-form';

import { CAMPAIGN_TYPES, LOCALES, DAYS, PUBLISHER_PLATFORMS, ICON_TYPE, GOOGLE_LOCALES } from '@app/constants';
import { Box, Icons, IconByType } from '@app/components';
import type { Campaign as CampaignType } from '@app/api';
import { useAuth, useLocale } from '@app/hooks';
import { branch as branchLib, currency, date, format, campaign as campaignLib, google as googleLib } from '@app/lib';

type SummaryFormPropTypes = {
  campaign: CampaignType | any;
};

const SummaryForm: React.FC<SummaryFormPropTypes> = ({ campaign }) => {
  const SCOPE_OPTIONS = {
    scope: 'components.Campaign.SummaryForm',
  };
  const { t } = useLocale();
  const { branch } = useAuth();
  const { getValues } = useFormContext();
  const formData = getValues();
  const defaultCity = campaignLib.defaultCity(branch);
  const isGooglePlatform = googleLib.isPlatformGoogle(campaign?.template);

  function getPublisherPlatform() {
    const publisherPlatforms = campaign.targeting.publisher_platforms || campaign.template.targeting.publisher_platforms;
    const foundedPlatforms = publisherPlatforms.map((platform: string, index: number) => {
      if (platform === PUBLISHER_PLATFORMS.FACEBOOK) {
        return (
          <div key={index} className="flex pr-2 items-center space-x-1 mb-2">
            <Icons.FacebookIcon /> <span className="text-sm capitalize"> {PUBLISHER_PLATFORMS.FACEBOOK}</span>
          </div>
        );
      }
      if (platform === PUBLISHER_PLATFORMS.INSTAGRAM) {
        return (
          <div key={index} className="flex pr-2 items-center space-x-1">
            <Icons.InstagramIcon /> <span className="text-sm capitalize"> {PUBLISHER_PLATFORMS.INSTAGRAM}</span>
          </div>
        );
      }
      if (platform === PUBLISHER_PLATFORMS.GOOGLE) {
        return (
          <div key={index} className="flex pr-2 items-center space-x-1">
            <Icons.GoogleIcon /> <span className="text-sm capitalize"> {PUBLISHER_PLATFORMS.GOOGLE}</span>
          </div>
        );
      }
    });
    return foundedPlatforms;
  }

  function getLanguages() {
    if (!Array.isArray(campaign.targeting.locales) && !isGooglePlatform) {
      return '-';
    }

    if (isGooglePlatform) {
      return GOOGLE_LOCALES.filter((locale) => campaign.targeting.locales.includes(locale.code))
        .map((locale) => locale.name)
        .join(', ');
    }

    return LOCALES.filter((locale) => campaign.targeting.locales.includes(locale.key))
      .map((locale) => locale.name)
      .join(', ');
  }

  function getInterests() {
    if (formData.keywords) {
      return [...formData.interests].map((interest) => interest.label).join(', ');
    }

    if (campaign?.targeting.interests) {
      return campaign?.targeting.interests.map((interest: { name: string }) => interest.name).join(', ');
    }

    return '-';
  }

  function getKeywords() {
    if (formData.keywords) {
      return [...formData.keywords].map((keyword) => keyword.label).join(', ');
    }

    if (campaign?.targeting.keywords) {
      return campaign?.targeting.keywords.map((keyword: string) => keyword).join(', ');
    }

    return '-';
  }

  function getCities() {
    if (formData?.cities) {
      return formData?.cities.map((city: { name: string; region: string }) => `${city.name} - ${city.region}`).join(', ');
    }
    return defaultCity;
  }

  function getGender() {
    if (Array.isArray(campaign?.targeting?.gender)) {
      return '-';
    }

    if (formData.gender) {
      return t(`genders.${String(formData.gender).toLocaleLowerCase()}` as string);
    }

    return t(`genders.${String(campaign?.targeting?.gender).toLocaleLowerCase()}`);
  }

  function getAgeRange() {
    if (Array.isArray(campaign?.targeting?.age_min)) {
      return '-';
    }

    if (formData.ageMin && formData.ageMax) {
      return `${formData.ageMin} - ${formData.ageMax}`;
    }

    return `${campaign.targeting.age_min} - ${campaign.targeting.age_max}`;
  }

  function getCompanyType() {
    if (formData.campaignType) {
      return t(`campaignTypes.${formData.campaignType}`);
    }

    if (isGooglePlatform && campaign.template.targeting.sub_campaign_type === 'PMAX') {
      return t(`campaignTypes.pmax`);
    }

    if (isGooglePlatform && campaign.template.targeting.sub_campaign_type === 'SMART') {
      return t(`campaignTypes.smart`);
    }

    return t('labels.campaignType', {
      ...SCOPE_OPTIONS,
      value: t(`labels.leadGen`, SCOPE_OPTIONS),
    });
  }

  function getIconType(): string | undefined {
    if (formData.campaignType === 'whatsappMessage') {
      return ICON_TYPE.WHATSAPP_MESSAGE;
    }

    if (formData.campaignType === 'messenger') {
      return ICON_TYPE.MESSENGER;
    }

    if (formData.campaignType === 'call') {
      return ICON_TYPE.CALL;
    }

    if (formData.campaignType === 'instagramVisit') {
      return ICON_TYPE.PERSON;
    }

    if (formData.campaignType === 'finalUrl') {
      return ICON_TYPE.FINAL_URL;
    }

    if (formData.campaignType === 'leadGeneration') {
      return ICON_TYPE.LEAD_GENERATION;
    }

    if (isGooglePlatform && campaign.template.targeting.sub_campaign_type === 'PMAX') {
      return ICON_TYPE.PMAX;
    }

    if (isGooglePlatform && campaign.template.targeting.sub_campaign_type === 'SMART') {
      return ICON_TYPE.SMART;
    }
  }

  function getStopDate() {
    if (formData.useStopTime && formData?.stopTime) {
      return date.format(formData?.stopTime, 'dd.LL.yyyy HH:mm');
    }

    if (campaign?.stop_time) {
      return date.format(campaign.stop_time, 'dd.LL.yyyy HH:mm');
    }

    return '-';
  }

  function getStartDate() {
    const nowDate = date.ISOtoJSDate(date.now());

    if (formData?.startTime) {
      if (nowDate > date.ISOtoJSDate(formData.startTime)) {
        return date.format(date.plusFromNow({ minutes: 2 }), 'dd.LL.yyyy HH:mm');
      }
      return date.format(formData.startTime, 'dd.LL.yyyy HH:mm');
    }

    if (nowDate > date.ISOtoJSDate(campaign.start_time)) {
      return date.format(date.plusFromNow({ minutes: 2 }), 'dd.LL.yyyy HH:mm');
    }
    return date.format(campaign.start_time, 'dd.LL.yyyy HH:mm');
  }

  function getScheduleDays() {
    const days = formData?.selectedDays;
    const findDays = days?.map((day: string) => {
      if (day === DAYS.NUMBERMONDAY) {
        return t('days.monday');
      }

      if (day === DAYS.NUMBERTUESDAY) {
        return t('days.tuesday');
      }

      if (day === DAYS.NUMBERWEDNESDAY) {
        return t('days.wednesday');
      }

      if (day === DAYS.NUMBERTHURSDAY) {
        return t('days.thursday');
      }

      if (day === DAYS.NUMBERFRIDAY) {
        return t('days.friday');
      }

      if (day === DAYS.NUMBERSATURDAY) {
        return t('days.saturday');
      }

      return t('days.sunday');
    });
    return findDays.join(', ');
  }

  function getScheduleTime() {
    return date.format(formData.startScheduleHour, 'HH:mm') + '-' + date.format(formData.stopScheduleHour, 'HH:mm');
  }

  function getRadius() {
    if (formData?.radius) {
      return formData?.radius;
    }

    return campaign?.targeting?.default_location_range;
  }

  return (
    <Box className="bg-transparent w-full">
      <div className="grid grid-cols-2 gap-4">
        <div className="bg-white border border-gray-200 rounded-3 flex flex-col p-6 mb-4 shadow-sm">
          <span className="font-semibold text-4 text-black-800">{t('labels.campaignType', SCOPE_OPTIONS)}</span>
          <span className="pt-4 pb-1 text-blue-500">
            <IconByType type={getIconType()} />
          </span>
          <span className="font-normal text-4 text-black-800">{getCompanyType()}</span>
          {[CAMPAIGN_TYPES.FINAL_URL, CAMPAIGN_TYPES.INSTAGRAM_VISIT, CAMPAIGN_TYPES.YEMEKSEPETI].includes(formData.campaignType) && (
            <>
              <hr className="my-2" />
              <span
                className="font-normal text-3.5 text-black-800 break-words"
                dangerouslySetInnerHTML={{
                  __html: t('labels.campaignUrl', SCOPE_OPTIONS),
                }}
              />
              <span className="font-normal text-3 text-gray-500 line-clamp-2 mt-1">{formData.linkData.link}</span>
            </>
          )}
        </div>
        <div className="bg-white border border-gray-200 rounded-3 flex flex-col p-6 mb-4 shadow-sm">
          <span className="font-semibold text-4 text-black-800">{t('labels.budget', SCOPE_OPTIONS)}</span>
          <span className="pt-4 pb-1 font-normal text-7 text-blue-600 line-clamp-2">
            {format.money(currency.decimal(formData.budget), branchLib.currency(branch))}
          </span>
        </div>
      </div>
      <div className="grid grid-cols-3 gap-4">
        <div className="flex flex-col">
          <div className="bg-white border border-gray-200 rounded-3 flex flex-col px-5 py-5.5 mb-4 shadow-sm h-fit">
            <span className="font-semibold text-4 text-black-800 mb-1.5">{t('labels.campaignDetail', SCOPE_OPTIONS)}</span>
            <div className="py-4">
              <span className="text-blue-600">
                <IconByType type={ICON_TYPE.CAMPAIGN_LIST} />
              </span>
              <span className="font-semibold text-4 py-2.5 text-black-800">{t('labels.name', SCOPE_OPTIONS)}</span>
              <span className="font-normal text-4 text-gray-500 line-clamp-2" dangerouslySetInnerHTML={{ __html: campaign.name }} />
            </div>

            <div className="py-4">
              <span className="py-2 text-blue-600">
                <IconByType type={ICON_TYPE.DATE} />
              </span>
              <span className="font-semibold text-4 py-2.5 text-black-800">{t('labels.startTime', SCOPE_OPTIONS)}</span>
              <span className="font-normal text-4  text-gray-500 line-clamp-2" dangerouslySetInnerHTML={{ __html: getStartDate() }} />
            </div>

            <div className="py-4">
              <span className="py-2 text-blue-600">
                <IconByType type={ICON_TYPE.DATE} />
              </span>
              <span className="font-semibold text-4 py-2.5 text-black-800">{t('labels.stopTime', SCOPE_OPTIONS)}</span>
              <span className="font-normal text-4  text-gray-500 line-clamp-2" dangerouslySetInnerHTML={{ __html: getStopDate() }} />
            </div>
          </div>
          {formData?.useAdSchedule && (
            <div className="bg-white border border-gray-200 rounded-3 flex flex-col px-5 py-5.5 shadow-sm h-fit">
              <span className="font-semibold text-4 text-black-800 mb-1.5">{t('labels.adSchedule', SCOPE_OPTIONS)}</span>
              <>
                <div className="py-4">
                  <span className="py-2 text-blue-600">
                    <IconByType type={ICON_TYPE.DATE} />
                  </span>
                  <span className="font-semibold text-4 py-2.5 text-black-800">{t('labels.days', SCOPE_OPTIONS)}</span>
                  <span className="font-normal text-4  text-gray-500 line-clamp-2">{getScheduleDays()}</span>
                </div>

                <div className="py-4">
                  <span className="py-2 text-blue-600">
                    <IconByType type={ICON_TYPE.CAMPAIGN_LIST} />
                  </span>
                  <span className="font-semibold text-4 py-2.5 text-black-800">{t('labels.timeRange', SCOPE_OPTIONS)}</span>
                  <span className="font-normal text-4  text-gray-500 line-clamp-2">{getScheduleTime()}</span>
                </div>
              </>
            </div>
          )}
        </div>

        <div className="bg-white border border-gray-200 rounded-3 flex flex-col px-5 py-5.5 mb-4 shadow-sm h-fit">
          <span className="font-semibold text-4 text-black-800 mb-1.5">{t('labels.targeting', SCOPE_OPTIONS)}</span>
          {!isGooglePlatform && (
            <>
              <div className="py-4">
                <span className="py-2 text-blue-600">
                  <IconByType type={ICON_TYPE.AGE_RANGE} />
                </span>
                <span className="font-semibold text-4 py-2.5 text-black-800">{t('labels.ageRange', SCOPE_OPTIONS)}</span>
                <span className="font-normal text-4 text-gray-500 line-clamp-2">{getAgeRange()}</span>
              </div>

              <div className="py-4">
                <span className="py-2 text-blue-600">
                  <IconByType type={ICON_TYPE.GENDER} />
                </span>
                <span className="font-semibold text-4 py-2.5 text-black-800">{t('labels.gender', SCOPE_OPTIONS)}</span>
                <span className="font-normal text-4 text-gray-500 line-clamp-2">{getGender()}</span>
              </div>
            </>
          )}

          <div className="py-4">
            <span className="py-2 text-blue-600">
              <IconByType type={ICON_TYPE.LANGUAGE} />
            </span>
            <span className="font-semibold text-4 py-2.5 text-black-800">{t('labels.languages', SCOPE_OPTIONS)}</span>
            <span className="font-normal text-4  text-gray-500 line-clamp-2">{getLanguages()}</span>
          </div>

          {!isGooglePlatform ? (
            <div className="py-4">
              <span className="py-2 text-blue-600">
                <IconByType type={ICON_TYPE.INTEREST} />
              </span>
              <span className="font-semibold text-4 py-2.5 text-black-800">{t('labels.interests', SCOPE_OPTIONS)}</span>
              <span className="font-normal text-4 text-gray-500 line-clamp-2 capitalize" title={getInterests()}>
                {getInterests()}
              </span>
            </div>
          ) : (
            <div className="py-4">
              <span className="py-2 text-blue-600">
                <IconByType type={ICON_TYPE.KEYWORDS} />
              </span>
              <span className="font-semibold text-4 py-2.5 text-black-800">{t('labels.keywords', SCOPE_OPTIONS)}</span>
              <span className="font-normal text-4 text-gray-500 line-clamp-2 capitalize" title={getKeywords()}>
                {getKeywords()}
              </span>
            </div>
          )}
        </div>

        <div className="flex flex-col h-fit">
          <div className="bg-white border border-gray-200 rounded-3 flex flex-col px-5 py-5.5 mb-4 shadow-sm h-fit ">
            <span className="font-semibold text-4 text-black-800 mb-1.5">{t('labels.locations', SCOPE_OPTIONS)}</span>
            {!formData?.radius && !campaign?.targeting?.default_location_range ? (
              <div className="py-4">
                <span className="py-2 text-blue-600">
                  <IconByType type={ICON_TYPE.LOCATION} />
                </span>
                <span className="font-semibold text-4 py-0.5 text-black-800">{t('labels.cities', SCOPE_OPTIONS)}</span>

                <span className="font-normal text-4  text-gray-500 line-clamp-2"> {getCities()}</span>
              </div>
            ) : (
              <div className="py-4">
                <span className="py-2 text-blue-600">
                  <IconByType type={ICON_TYPE.LOCATION} />
                </span>
                <span className="font-semibold text-4 py-0.5 text-black-800">{t('labels.radius', SCOPE_OPTIONS)}</span>

                <span className="font-normal text-4  text-gray-500 line-clamp-2"> {getRadius() + ' KM '}</span>
              </div>
            )}
          </div>
          <div className="bg-white border border-gray-200 rounded-3 flex flex-col px-5 py-5.5 shadow-sm h-fit">
            <span className="font-semibold text-4 text-black-800 mb-1.5">{t('labels.others', SCOPE_OPTIONS)}</span>
            <span className="pt-4 pb-2 text-blue-600">
              <IconByType type={ICON_TYPE.PLATFORM} />
            </span>
            <span className="font-semibold text-4 pb-2 text-black-800">{t('labels.platform', SCOPE_OPTIONS)}</span>

            <span className="font-normal text-4 text-black-800">{getPublisherPlatform()}</span>
          </div>
        </div>
      </div>
    </Box>
  );
};

export default SummaryForm;
