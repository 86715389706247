import React, { useEffect, useState } from 'react';
import Select, { StylesConfig } from 'react-select';

import { useAuth, useLocale } from '@app/hooks';
import { INTEGRATION_TYPES } from '@app/constants';
import { Icons } from '@app/components';

type CustomSelectPropTypes = {
  integration?: string;
};

const customStyles: StylesConfig = {
  menu: (base) => ({
    ...base,
    marginTop: -20,
    padding: 4,
    paddingTop: 20,
    borderRadius: 10,
    boxShadow: 'none',
  }),
  control: (base) => ({
    ...base,
    zIndex: 2,
    background: '#D85858',
    border: 'none',
    borderRadius: 30,
    '&:hover': {
      border: 'none',
    },
    '&': {
      boxShadow: 'none',
      outline: 'none',
    },
  }),
  placeholder: (base) => ({
    ...base,
    fontSize: 14,
    fontWeight: 500,
    color: 'white',
  }),
  option: (base) => ({
    ...base,
    backgroundColor: 'none',
    width: '100%',
    cursor: 'pointer',
    fontSize: 16,
    fontWeight: '500',
    '&:hover': {
      backgroundColor: '#F7F7F8',
      borderRadius: 30,
    },
  }),
  indicatorSeparator: (base) => ({
    ...base,
    display: 'none',
  }),
  dropdownIndicator: (base) => ({
    ...base,
    color: 'white',
    '&:hover': {
      color: 'white',
    },
  }),
  valueContainer: (base) => ({
    ...base,
    justifyItems: 'center',
  }),
};

const CustomSelect: React.FC<CustomSelectPropTypes> = ({ integration }) => {
  const SCOPE_OPTIONS = {
    scope: 'components.Integration.CustomSelect',
  };

  const [redirectUrl, setRedirectUrl] = useState<string>('');

  const { t } = useLocale();
  const { token, branchId } = useAuth();

  useEffect(() => {
    setRedirectUrl(window.location.origin);
  }, []);

  const options = [
    {
      value: INTEGRATION_TYPES.FACEBOOK,
      label: (
        <a
          href={`${process.env.NEXT_PUBLIC_FACEBOOK_INTEGRATION_URL}/?token=${token?.access_token}&id=${branchId}&redirect_url=${redirectUrl}`}
          className="flex items-center"
        >
          <Icons.FacebookIcon className="w-5 h-5 text-blue-500 mr-2" />
          {t('options.facebook', SCOPE_OPTIONS)}
        </a>
      ),
    },
    // {
    //   value: INTEGRATION_TYPES.GOOGLE,
    //   label: (
    //     <a
    //       href={`${process.env.NEXT_PUBLIC_GOOGLE_INTEGRATION_URL}/?token=${token?.access_token}&id=${branchId}&redirect_url=${redirectUrl}`}
    //       className="flex items-center"
    //     >
    //       <Icons.Google className="w-5 h-5 text-blue-500 mr-2" />
    //       {t('options.google', SCOPE_OPTIONS)}
    //     </a>
    //   ),
    // },
  ];

  function handlerOptions() {
    const integrationOption = options.filter((item) => item.value === integration);
    if (integration === 'facebook') {
      return integrationOption;
    }

    if (integration === 'google') {
      return integrationOption;
    }

    return options;
  }
  return (
    <Select
      styles={customStyles}
      options={handlerOptions()}
      classNamePrefix="react-select !rounded-full"
      placeholder={t('labels.placeholder', SCOPE_OPTIONS)}
      isSearchable={false}
    />
  );
};

export default CustomSelect;
