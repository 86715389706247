import React, { useEffect, useState } from 'react';

import { useFormContext } from 'react-hook-form';

import { Box, Form, Icons } from '@app/components';
import { useAuth, useLocale } from '@app/hooks';
import type { Campaign as CampaignType, BranchCampaign as BranchCampaignType } from '@app/api';

import GmSettingsTab from './GmSettingsTab';
import { branch as branchLib } from '@app/lib';

type GoogleSettingsFormPropTypes = {
  campaign: Partial<CampaignType> & Partial<BranchCampaignType>;
  budget?: number;
  radius?: number;
  isEdit?: boolean;
};

const GoogleSettingsForm: React.FC<GoogleSettingsFormPropTypes> = ({ campaign, budget, radius, isEdit }) => {
  const SCOPE_OPTIONS = {
    scope: 'components.Campaign.GoogleSettingsForm',
  };
  const { t } = useLocale();
  const { branch } = useAuth();
  const branchCurrency = branchLib.currency(branch);
  const MIN_BUDGET = branchLib.minimumBudgetBranch(branch);
  const MAX_BUDGET = 500;
  const {
    register,
    formState: { errors },
    watch,
    control,
    setValue,
  } = useFormContext();
  const budgetWatch = watch('budget', !!budget);
  const isUsingGmSettingsWatch = watch('useGmSettings', true);
  const [maxBudget, setMaxBudget] = useState(MAX_BUDGET);
  useEffect(() => {
    if (budget) {
      setValue('budget', budget);
    }
  }, [budget]);

  return (
    <Box className="w-full">
      <Box className="bg-white shadow-sm px-4">
        <Form.Input
          type="text"
          id="name"
          name="name"
          label={t('form.name.label', SCOPE_OPTIONS)}
          placeholder={t('form.name.placeholder', SCOPE_OPTIONS)}
          defaultValue={campaign.name}
          disabled={true}
          requiredSign={true}
        />
      </Box>
      <Box className="bg-white shadow-sm px-4">
        <div className="flex items-center justify-between mt-5">
          <Form.Range
            label={t('form.budget.label', SCOPE_OPTIONS)}
            id="budget"
            className="w-3/4 border-none"
            defaultValue={budget ?? MIN_BUDGET}
            control={control}
            minValue={MIN_BUDGET}
            maxValue={maxBudget}
            error={errors.budget}
            {...register('budget', {
              onChange: (e) => {
                const value = e.target.value;

                setValue('budget', value);
              },
              min: {
                value: MIN_BUDGET,
                message: t('form.budget.errors.min', {
                  ...SCOPE_OPTIONS,
                  min: MIN_BUDGET,
                  currency: branchCurrency,
                }),
              },
              required: {
                value: true,
                message: t('form.budget.errors.required', SCOPE_OPTIONS),
              },
            })}
          />
          <div className="flex flex-col justify-between mb-6 w-1/4 px-4 relative items-start">
            <span className="flex !w-[85%]">
              <input
                type="text"
                id="budgetText"
                value={`${budgetWatch ?? MIN_BUDGET} ${branchCurrency}`}
                onFocus={(event) => event.target.select()}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  const value = Number(event.target.value.replace(` ${branchCurrency}`, ''));

                  if (!Number.isNaN(value)) {
                    value <= 2000 && setValue('budget', value, { shouldValidate: true });
                  }
                  value > MAX_BUDGET && value <= 2000 && setMaxBudget(value);
                }}
                className="flex items-center text-center border-x-0 border-t-0 border-b-0.5 border-gray-400 focus:border-gray-400 text-blue-600 text-8 font-semibold justify-center whitespace-nowrap"
              />
            </span>
            <Icons.CircleEdit
              onClick={() => document.getElementById('budgetText')?.focus()}
              className="w-9 h-9 absolute top-[30%] right-0 !text-blue-600 stroke-2"
            />
          </div>
        </div>
      </Box>

      {!isEdit && (
        <div className="flex items-center justify-between border-t mt-4 mb-4 border-gray-500 ">
          <span className="mt-4 font-semibold">{t('dateForm.labels.useGmSettings', SCOPE_OPTIONS)}</span>
          <Form.Checkbox
            id="useGmSettings"
            className="mt-4"
            {...register('useGmSettings')}
            label={t('dateForm.labels.updateSettings', SCOPE_OPTIONS)}
          />
        </div>
      )}
      {(isUsingGmSettingsWatch || isEdit) && <GmSettingsTab campaign={campaign} radius={radius} isEdit={isEdit} />}
    </Box>
  );
};

export default GoogleSettingsForm;
