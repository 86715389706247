import React, { useEffect } from 'react';

import { useFormContext, useWatch } from 'react-hook-form';
import cn from 'classnames';

import { useLocale } from '@app/hooks';
import { Box, Form, Icons } from '@app/components';
import { array, date as dateLib } from '@app/lib';
import { DAYS } from '@app/constants';

import CustomInput from './CustomInput';
import type { Campaign as CampaignType } from '@app/api';

const Schedule = ({ startTime, stopTime }: { startTime: string; stopTime: string; campaign?: CampaignType }) => {
  const SCOPE_OPTIONS = {
    scope: 'components.Campaign.SettingsForm.dateForm',
  };
  const { t, locale } = useLocale();
  const {
    setValue,
    register,
    control,
    watch,
    formState: { errors },
    unregister,
    trigger,
  } = useFormContext();
  const defaultStartScheduleHour = getStartScheduleHour();
  const defaultStopScheduleHour = getStopScheduleHour();
  const startScheduleHourWatch = useWatch({
    control,
    name: 'startScheduleHour',
    defaultValue: defaultStartScheduleHour,
  });
  const stopScheduleHourWatch = useWatch({
    control,
    name: 'stopScheduleHour',
    defaultValue: defaultStopScheduleHour,
  });
  const useAdScheduleWatch = watch('useAdSchedule');
  const selectedDaysWatch = watch('selectedDays');
  const startTimeWatch = startTime;
  const stopTimeWatch = stopTime;
  const daysInRange = dateLib.getDatesInRange(startTimeWatch, stopTimeWatch, locale);

  const DAYS_OPTIONS = [
    { label: t('days.monday'), value: DAYS.NUMBERMONDAY },
    { label: t('days.tuesday'), value: DAYS.NUMBERTUESDAY },
    { label: t('days.wednesday'), value: DAYS.NUMBERWEDNESDAY },
    { label: t('days.thursday'), value: DAYS.NUMBERTHURSDAY },
    { label: t('days.friday'), value: DAYS.NUMBERFRIDAY },
    { label: t('days.saturday'), value: DAYS.NUMBERSATURDAY },
    { label: t('days.sunday'), value: DAYS.NUMBERSUNDAY },
  ];

  useEffect(() => {
    if (useAdScheduleWatch === false) {
      unregister('selectedDays');
      unregister('startScheduleHour');
      unregister('stopScheduleHour');
    }
  }, [useAdScheduleWatch]);

  useEffect(() => {
    const daysInRangeToValues = daysInRange.map((day) => DAYS_OPTIONS.find((option) => option.label === day)?.value);
    const selectedDays = array.isEmpty(selectedDaysWatch) ? [] : selectedDaysWatch;
    setValue(
      'selectedDays',
      selectedDays.filter((day: any) => daysInRangeToValues.includes(day))
    );
  }, [startTimeWatch, stopTimeWatch]);

  useEffect(() => {
    trigger('useAdSchedule');
  }, [selectedDaysWatch, useAdScheduleWatch]);

  function getStartScheduleHour() {
    return dateLib.set(dateLib.now(), { hour: 8, minute: 0 });
  }

  function getStopScheduleHour() {
    return dateLib.set(dateLib.now(), { hour: 17, minute: 0 });
  }

  return (
    <>
      <Box className="bg-white h-fit px-5 py-5.5">
        <div className="mt-2">
          <div className="flex items-center justify-between">
            <span className="font-semibold text-black-800">
              {t('labels.schedule', SCOPE_OPTIONS)}
              <span className="text-red-400">*</span>
            </span>

            <Form.Checkbox
              id="useAdSchedule"
              className=""
              {...register('useAdSchedule', {
                validate: (value) => {
                  if (!value) {
                    return true;
                  }

                  if (array.isEmpty(selectedDaysWatch)) {
                    return t('message.errors.selectDay', SCOPE_OPTIONS);
                  }

                  return true;
                },
              })}
              error={errors.useAdSchedule}
              label={t('labels.useAddSchedule', SCOPE_OPTIONS)}
            />
          </div>
        </div>
      </Box>
      {useAdScheduleWatch && (
        <>
          <Box className="bg-white p-5">
            <div className="flex flex-row items-center justify-between">
              <span className="py-2 text-4 font-semibold">{t('labels.dayPlan', SCOPE_OPTIONS)}</span>

              {errors.useAdSchedule && (
                <div className="flex items-center">
                  <span className="inline text-red-400 text-3">{errors.useAdSchedule?.message}</span>
                </div>
              )}
            </div>

            <div className="flex mt-2 gap-14 items-center ">
              {DAYS_OPTIONS.map((day) => (
                <div className="grid grid-row-4" key={String(day.value)}>
                  <div className="mt-2">
                    <label
                      className={cn('block text-sm text-gray-900', {
                        'text-gray-400': !daysInRange.includes(day.label),
                      })}
                    >
                      {day.label}
                    </label>
                  </div>
                  <Form.Checkbox
                    id="selectedDays"
                    className={cn('mt-1.5', {
                      'text-gray-400 border-gray-500': !daysInRange.includes(day.label),
                    })}
                    {...register('selectedDays')}
                    value={Number(day.value)}
                    day
                    error={errors.selectedDays}
                    disabled={!daysInRange.includes(day.label)}
                  />
                </div>
              ))}
            </div>
          </Box>

          <Box className="bg-white p-5">
            <span className="py-2 text-4">{t('labels.hourPlan', SCOPE_OPTIONS)}</span>
            <div className="flex items-center justify-between">
              <div className="flex items-center space-x-4">
                <div className="mt-3 flex items-center space-x-3">
                  <span className="text-3.5 text-black-800">{t('labels.start', SCOPE_OPTIONS)}</span>
                  <Form.Date
                    control={control}
                    id="startScheduleHour"
                    name="startScheduleHour"
                    showTimeSelect
                    showTimeSelectOnly
                    timeIntervals={30}
                    dateFormat="HH:mm"
                    defaultValue={startScheduleHourWatch}
                    customInput={<CustomInput scheduleHour />}
                  />
                </div>
                <div className="mt-3 flex items-center space-x-3">
                  <span className="text-3.5 text-black-800">{t('labels.end', SCOPE_OPTIONS)}</span>
                  <Form.Date
                    id="stopScheduleHour"
                    name="stopScheduleHour"
                    showTimeSelect
                    showTimeSelectOnly
                    timeIntervals={30}
                    dateFormat="HH:mm"
                    control={control}
                    defaultValue={stopScheduleHourWatch}
                    customInput={<CustomInput scheduleHour />}
                  />
                </div>
              </div>
              <div className="flex items-center space-x-2">
                <Icons.Checkmark className="text-green-500 w-5 h-5" />
                <span>
                  {`${dateLib.format(startScheduleHourWatch, 'HH:mm')}
                      -
                      ${dateLib.format(stopScheduleHourWatch, 'HH:mm')}
                      ${t('labels.checkSettings', SCOPE_OPTIONS)}`}
                </span>
              </div>
            </div>
          </Box>
        </>
      )}
    </>
  );
};

export default Schedule;
