import React, { useEffect } from 'react';

import { useFormContext, useWatch } from 'react-hook-form';

import { branch as branchLib, date as dateLib } from '@app/lib';
import { useAuth, useLocale } from '@app/hooks';
import { Box, Form, Icons, InfoBox } from '@app/components';

import CustomInput from './CustomInput';

type EndDatePropTypes = {
  stopTime?: string;
  campaignStopTime?: string;
  isEdit?: boolean;
  minDateRange?: number;
  isGooglePlatform?: boolean;
};

const EndDate: React.FC<EndDatePropTypes> = ({ stopTime, campaignStopTime, isEdit, minDateRange, isGooglePlatform }) => {
  const SCOPE_OPTIONS = {
    scope: 'components.Campaign.DateForm.EndDate',
  };
  const { t } = useLocale();
  const { branch } = useAuth();
  const {
    register,
    control,
    watch,
    setValue,
    formState: { errors },
  } = useFormContext();
  const defaultDate = getDefaultDate();
  const stopTimeWatch = useWatch({
    control,
    name: 'stopTime',
    defaultValue: defaultDate,
  });
  const startTimeWatch = watch('startTime');

  const minDate = dateLib.JSDateToISO(dateLib.plus(dateLib.ISOtoJSDate(startTimeWatch), { hours: 24 }));
  const minMaxTime = dateLib.isValid(stopTime) ? getMinMaxTime() : { maxTime: undefined, minTime: undefined };
  const isUsingStopTimeWatch = useWatch({ control, name: 'useStopTime', defaultValue: isEdit && stopTime });
  const MINIMUM_DATE = branchLib.minCampaignDateRange(branch);

  useEffect(() => {
    if (!isUsingStopTimeWatch && dateLib.isValid(stopTime)) {
      setValue('stopTime', stopTime);
    } else {
      setValue('stopTime', getDefaultDate());
    }
  }, [isUsingStopTimeWatch, stopTime]);

  function getDefaultDate() {
    const plusWeekToNow = dateLib.plusFromNow({ days: 7 });
    if (stopTime) {
      return stopTime;
    }

    if (!stopTime && isUsingStopTimeWatch) {
      return plusWeekToNow;
    }

    return undefined;
  }

  function handleStopTimeLabel(dateFormat: string) {
    if (!isUsingStopTimeWatch && campaignStopTime) {
      return dateLib.format(campaignStopTime, dateFormat);
    }

    if (!campaignStopTime && !isUsingStopTimeWatch) {
      return '-';
    }

    return dateLib.format(stopTimeWatch, dateFormat);
  }

  function getMinMaxTime() {
    const minDiffDate = minDate && dateLib.differenceDate(dateLib.ISOtoJSDate(minDate), dateLib.ISOtoJSDate(stopTimeWatch));
    const stopDiffDate = stopTime && dateLib.differenceDate(dateLib.ISOtoJSDate(stopTime), dateLib.ISOtoJSDate(stopTimeWatch));

    if (stopDiffDate === 1 && stopTime) {
      return {
        minTime: dateLib.ISOtoJSDate(
          dateLib.set(stopTime, {
            hour: 0,
            minute: 0,
          })
        ),
        maxTime: dateLib.ISOtoJSDate(dateLib.format(stopTime, 'HH:mm')),
      };
    }

    if (minDiffDate > 0 && stopDiffDate !== 1) {
      return {
        minTime: dateLib.ISOtoJSDate(
          dateLib.set(dateLib.now(), {
            hour: 0,
            minute: 0,
          })
        ),
        maxTime: dateLib.ISOtoJSDate(
          dateLib.set(dateLib.now(), {
            hour: 23,
            minute: 59,
          })
        ),
      };
    }

    return {
      minTime: dateLib.ISOtoJSDate(dateLib.format(minDate, 'HH:mm')),
      maxTime: dateLib.ISOtoJSDate(
        dateLib.set(minDate, {
          hour: 23,
          minute: 59,
        })
      ),
    };
  }
  return (
    <Box className="bg-white p-5 !mb-0">
      <div className="flex items-center justify-between mb-1.5">
        <label className="block text-3.5 font-semibold text-gray-900 whitespace-nowrap mr-2">
          {t('labels.stopDate', SCOPE_OPTIONS)}
          <span className="text-red-400">*</span>
        </label>
        {errors.stopTime && (
          <div className="flex items-center flex-end">
            <span className="inline text-red-400 text-3">{errors.stopTime.message}</span>
          </div>
        )}
      </div>
      <div className="flex items-center space-x-4">
        <InfoBox className="w-3/4">
          <div className="flex items-center space-x-2 text-center rounded-1.5 text-black-800 text-3.5 font-semibold transition-all">
            <Icons.Date className="w-5 h-5" />
            <span>{handleStopTimeLabel('dd MMM yyyy')}</span>
          </div>
        </InfoBox>
        {!isGooglePlatform && (
          <InfoBox className="w-1/4">
            <div className="flex items-center space-x-2 text-center rounded-1.5 text-black-800 text-3.5 font-semibold transition-all">
              <Icons.ClockCircle className="w-5 h-5" />
              <span>{handleStopTimeLabel('HH:mm')} </span>
            </div>
          </InfoBox>
        )}
      </div>
      <div className="border-b border-gray-400 w-full my-6"></div>
      <div className="flex flex-col">
        <Form.Checkbox
          id="useStopTime"
          className="py-4"
          {...register('useStopTime', { value: isUsingStopTimeWatch })}
          label={t('labels.useStopTime', SCOPE_OPTIONS)}
        />
        {isUsingStopTimeWatch && (
          <Form.Date
            id="stopTime"
            name="stopTime"
            dateFormat={isGooglePlatform ? 'd MMMM yyyy' : 'd MMMM yyyy HH:mm'}
            timeIntervals={1}
            showTimeSelect={!isGooglePlatform ?? true}
            control={control}
            error={errors.stopTime}
            rules={{
              validate: (date: string) => {
                const diff = dateLib.diff(startTimeWatch, date, ['hours']);
                const diffDays = dateLib.diff(startTimeWatch, date, ['days']);

                if (minDateRange) {
                  if ((diffDays.days as number) < minDateRange) {
                    return t('errors.validate', { ...SCOPE_OPTIONS, value: `${minDateRange}` });
                  }
                }
                if ((diff.hours as number) < MINIMUM_DATE * 24) {
                  return t('errors.validate', { ...SCOPE_OPTIONS, value: MINIMUM_DATE });
                }
              },
            }}
            customInput={<CustomInput />}
            minDate={dateLib.ISOtoJSDate(minDate)}
            maxDate={dateLib.isValid(stopTime) ? dateLib.ISOtoJSDate(stopTime as string) : undefined}
            minTime={minMaxTime.minTime}
            maxTime={minMaxTime.maxTime}
          />
        )}
      </div>
    </Box>
  );
};

export default EndDate;
