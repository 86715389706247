import { Fragment } from 'react';

import { useFormContext } from 'react-hook-form';
import { Tab } from '@headlessui/react';
import cn from 'classnames';

import { campaign as campaignLib, number, interests, google as googleLib } from '@app/lib';
import { useAuth, useLocale } from '@app/hooks';
import { Box, Form } from '@app/components';
import { GENDERS, TAB_VALUES } from '@app/constants';
import type { BranchCampaignTargetingKeywordThemes, BranchCampaign as BranchCampaignType, Campaign as CampaignType } from '@app/api';

import InterestInput from './InterestInput';
import LocationForm from './LocationForm';
import DateForm from './DateForm';
import KeywordsInput from './KeywordsInput';
import NegativeKeywordsInput from './NegativeKeywordsInput';

type GmSettingsType = {
  campaign: Partial<CampaignType> & Partial<BranchCampaignType>;
  radius?: number;
  isEdit?: boolean;
  campaignStopTime?: string;
};

const GmSettingsTab: React.FC<GmSettingsType> = ({ campaign, campaignStopTime, radius, isEdit }) => {
  const SCOPE_OPTIONS = {
    scope: 'components.Campaign.SettingsForm',
  };
  const { t } = useLocale();
  const {
    watch,
    control,
    formState: { errors },
  } = useFormContext();
  const { branch } = useAuth();

  const ageMinWatch = watch('ageMin');
  const ageMaxWatch = watch('ageMax');
  const gendersAllow = campaignLib.hasPermission(campaign?.permissions, 'genders');
  const interestsAllow = campaignLib.hasPermission(campaign?.permissions, 'interests');
  const agesAllow = campaignLib.hasPermission(campaign?.permissions, 'ages');
  const locationsAllow = campaignLib.hasPermission(campaign?.permissions, 'locations');
  const targetingAllow = gendersAllow || interestsAllow || agesAllow;
  const tabName = [
    { name: t('GmSettingsTab.labels.targeting', SCOPE_OPTIONS), value: TAB_VALUES.TARGETING, key: 0, display: targetingAllow ?? false },
    { name: t('GmSettingsTab.labels.locations', SCOPE_OPTIONS), value: TAB_VALUES.LOCATION, key: 1, display: locationsAllow ?? false },
    { name: t('GmSettingsTab.labels.time', SCOPE_OPTIONS), value: TAB_VALUES.TIME, key: 2, display: true },
  ];
  const hasRange = campaignLib.hasDefaultRange(campaign);
  const defaultCity = campaignLib.defaultCity(branch) ? campaignLib.defaultCity(branch) : t('form.location.nearRadius', SCOPE_OPTIONS);
  const minAges = [18, 25, 35, 45, 55, 65];
  const maxAges = [24, 34, 44, 54, 64];
  const isGooglePlatform = googleLib.isPlatformGoogle(campaign?.template);
  const isNegativeKeywordAllow = interestsAllow && isGooglePlatform;
  const gendersMap = Object.values(GENDERS).map((gender) => ({
    label: t(`genders.${gender.toLocaleLowerCase()}`),
    value: gender,
  }));

  function getLocationValue() {
    if (hasRange) {
      return radius;
    }
    return defaultCity;
  }

  function getLocationLabel() {
    if (hasRange) {
      return t('form.range.label', SCOPE_OPTIONS);
    }
    return t('form.location.label', SCOPE_OPTIONS);
  }

  function transformFreeFormKeywordThemesArray(keywordThemesArray: Array<string> | undefined) {
    if (keywordThemesArray === undefined || keywordThemesArray.length === 0) {
      return [];
    }

    return keywordThemesArray?.map((item) => ({
      label: item?.toLocaleLowerCase(),
      value: item?.toLocaleLowerCase(),
      keywordType: 'free_form_keyword',
    }));
  }

  function transformKeywordThemesArray(keywordThemesArray: Array<BranchCampaignTargetingKeywordThemes> | undefined) {
    if (keywordThemesArray === undefined || keywordThemesArray.length === 0) {
      return [];
    }

    return keywordThemesArray?.map((item) => ({
      label: item.text,
      value: item.id,
      keywordType: 'keyword',
    }));
  }

  function handleKeywordsInputValue() {
    if (isEdit) {
      return [
        ...transformFreeFormKeywordThemesArray(campaign?.targeting?.free_form_keyword_themes),
        ...transformKeywordThemesArray(campaign?.targeting?.keyword_themes),
      ];
    } else {
      return transformFreeFormKeywordThemesArray(campaign?.targeting?.keywords);
    }
  }

  return (
    <div className="w-full px-2 sm:px-0">
      <Tab.Group>
        <Tab.List className="flex items-center rounded-xl p-1">
          {tabName.map(
            (category) =>
              category.display && (
                <Tab as={Fragment} key={category.key}>
                  {({ selected }) => (
                    <button
                      className={cn(
                        'w-full py-2.5 text-lg font-semibold leading-5 text-gray-500 border-b-0.5 flex items-center justify-center  border-gray-500 hover:text-gray-600 ',
                        { 'border-blue-600 text-blue-600 hover:text-blue-600': selected }
                      )}
                    >
                      {category.name}
                    </button>
                  )}
                </Tab>
              )
          )}
        </Tab.List>

        <Tab.Panels>
          {tabName.map(
            (category) =>
              category.display && (
                <Tab.Panel key={category.key}>
                  {category.value === TAB_VALUES.TARGETING && (
                    <>
                      <Box className="bg-white px-5 mt-5">
                        <div className="grid grid-cols-3 gap-4">
                          {gendersAllow && !isGooglePlatform && (
                            <Form.Select
                              name="gender"
                              label={t('form.gender.label', SCOPE_OPTIONS)}
                              rules={{
                                required: { value: true, message: t('form.gender.errors.required', SCOPE_OPTIONS) },
                              }}
                              control={control}
                              options={gendersMap}
                              error={errors.gender}
                              defaultValue={campaign?.targeting?.gender}
                              className="mb-4 border-none"
                            />
                          )}

                          {agesAllow && !isGooglePlatform && (
                            <>
                              <div>
                                <Form.Select
                                  name="ageMin"
                                  label={t('form.ageMin.label', SCOPE_OPTIONS)}
                                  rules={{
                                    required: { value: true, message: t('form.ageMin.errors.required', SCOPE_OPTIONS) },
                                    min: { value: 18, message: t('form.ageMin.errors.min', { ...SCOPE_OPTIONS, value: 18 }) },
                                    validate: (age: number) => {
                                      if (age <= ageMaxWatch) {
                                        return true;
                                      }

                                      return t('form.ageMin.errors.max', SCOPE_OPTIONS);
                                    },
                                  }}
                                  control={control}
                                  options={(isGooglePlatform ? minAges : number.generateInRange(18, 65)).map((index) => ({
                                    value: index,
                                    label: String(index),
                                  }))}
                                  error={errors.ageMin}
                                  defaultValue={campaign.targeting?.age_min}
                                />
                              </div>
                              <div>
                                <Form.Select
                                  name="ageMax"
                                  label={t('form.ageMax.label', SCOPE_OPTIONS)}
                                  rules={{
                                    required: { value: true, message: t('form.ageMax.errors.required', SCOPE_OPTIONS) },
                                    max: { value: 65, message: t('form.ageMax.errors.max', { ...SCOPE_OPTIONS, value: 65 }) },
                                    validate: (age: number) => {
                                      if (age >= ageMinWatch) {
                                        return true;
                                      }

                                      return t('form.ageMax.errors.min', SCOPE_OPTIONS);
                                    },
                                  }}
                                  control={control}
                                  options={(isGooglePlatform ? maxAges : number.generateInRange(18, 65)).map((index) => ({
                                    value: index,
                                    label: String(index),
                                  }))}
                                  error={errors.ageMax}
                                  defaultValue={campaign.targeting?.age_max}
                                />
                              </div>
                            </>
                          )}
                        </div>

                        {interestsAllow && isGooglePlatform ? (
                          <KeywordsInput defaultValue={handleKeywordsInputValue()} />
                        ) : (
                          <InterestInput defaultValue={interests.dataToInput(campaign?.targeting)} />
                        )}
                      </Box>
                      {isNegativeKeywordAllow ? (
                        <Box className="bg-white px-5 mt-5">
                          <NegativeKeywordsInput />
                        </Box>
                      ) : null}
                    </>
                  )}
                  {category.value === TAB_VALUES.LOCATION &&
                    (locationsAllow ? (
                      <LocationForm campaign={campaign} radius={radius} />
                    ) : (
                      <Box className="px-5 py-4 mt-5">
                        <Form.Input
                          type="text"
                          id="location"
                          name="location"
                          label={getLocationLabel()}
                          defaultValue={getLocationValue()}
                          disabled={true}
                        />
                      </Box>
                    ))}
                  {category.value === TAB_VALUES.TIME && (
                    <DateForm campaign={campaign} isEdit={isEdit} campaignStopTime={campaignStopTime} />
                  )}
                </Tab.Panel>
              )
          )}
        </Tab.Panels>
      </Tab.Group>
    </div>
  );
};
export default GmSettingsTab;
