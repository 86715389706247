import React from 'react';

import { branch as branchLib } from '@app/lib';

import { useRouter } from 'next/router';
import cn from 'classnames';

import { Icons } from '@app/components';
import type { BranchCompany } from '@app/api';

type LogoPropTypes = {
  miniSidebar?: boolean;
  company: BranchCompany;
};

const Logo: React.FC<LogoPropTypes> = ({ miniSidebar, company }) => {
  const router = useRouter();
  const logo = branchLib.logo(company);

  function handleLogoPress() {
    router.push('/');
  }

  if (logo?.thumbnailUrl) {
    return (
      <button type="button" onClick={handleLogoPress} className="flex flex-row items-center  px-4 w-full">
        <img
          src={logo?.thumbnailUrl}
          className={cn('bg-white border-1 border-white rounded-2 transition-all', {
            'w-9 h-9': !miniSidebar,
            'w-12 h-12': miniSidebar,
          })}
          alt={company.name}
        />
      </button>
    );
  }

  return (
    <button type="button" onClick={handleLogoPress} className="">
      <div className="">
        <Icons.Logo />
      </div>
    </button>
  );
};

export default Logo;
